import _ from 'lodash'
import React from 'react'
import classnames from 'classnames'
import { navigate } from 'gatsby'
import IconArrow from '../../assets/icons/dropdwon-arrow.svg'
import IconClosed from '../../assets/icons/pacificcross-icon-close.svg'
import './subsubmenu-mobile-styles.scss'

const SubMenuMobile = ({
  data,
  onClosedHandle,
  onSelectSubSubMenu,
  groupTitle,
  t,
}) => {
  const subGroupTitle = groupTitle === 'our-products'
    ? data.title
    : t(`menu.${groupTitle}.children.${data.title}.title`,{
      country: process.env.GATSBY_COUNTRY,
    }
  )

  console.log('data ', t(`menu.${groupTitle}.children.${data.title}.title`,{
    country: process.env.GATSBY_COUNTRY,
  }
))

console.log('data 1 ', groupTitle)

console.log('data 2 ', subGroupTitle)

  const containerSubMenuMobile = classnames({
    mobileSubSubMenu__container: true,
    mobileSubSubMenu__container__open: !_.isEmpty(data),
  })

  const goTo = (slug) => {
    let masterSlug
    switch (true) {
      case data.id === 'experience':
        masterSlug = `/experience/${slug}/`
        break
      case data.id === 'how-to-claim':
        masterSlug = '/how-to-claim/'
        break
      default:
        masterSlug = `/${slug}`
        break
    }

    navigate(masterSlug)
    onClosedHandle()
  }

  const getTextSubGroup = (i, ix) => (groupTitle === 'our-products'
    || (groupTitle === 'matter' && data.title !== 'knowledge')
    || data.id === 'portal-apps'
    ? i.title || i.name
    : t(
      `menu.${groupTitle}.children.${data.title}.subgroup.${[
        ix,
      ]}.children.${i.title}`,
    ))

  const getText = (i) => (groupTitle === 'our-products'
    || groupTitle === 'matter'
    || data.id === 'portal-apps'
    ? i.title || i.name
    : t(`menu.${groupTitle}.children.${data.title}.children.${i.title}`))

  const LinkList = ({ item, index }) => {
    if (item.url) {
      return (
        <li key={item.title}>
          <a href={item.url} target="_blank" rel="noreferrer">
            <p className="mobileSubSubMenu__row__paragraph">
              {getTextSubGroup(item, index)}
            </p>
          </a>
        </li>
      )
    }
    return (
      <li onClick={() => goTo(item.slug)} key={item.title}>
        <p className="mobileSubSubMenu__row__paragraph">
          {getTextSubGroup(item, index)}
        </p>
      </li>
    )
  }
  return (
    <div className={containerSubMenuMobile}>
      <div className="mobileSubSubMenu__close__btn">
        <IconArrow
          width="36"
          onClick={() => onSelectSubSubMenu({})}
          fill="#1C75BC"
        />
        <p>{subGroupTitle}</p>
        <IconClosed width="20" onClick={onClosedHandle} fill="#1C75BC" />
      </div>
      <div>
        {data.subgroup ? (
          <div className="mobileSubSubMenu__row">
            {_.map(data.subgroup, (item, index) => (
              <div key={index + item.slug}>
                {item.title !== 'apps' && (
                  <p className="mobileSubSubMenu__subgroupTitle">{item.title}</p>
                )}
                {item.title !== 'apps' && (
                  <ul>
                    {_.map(item.children, (child) => (
                      <LinkList item={child} index={index} />
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="mobileSubSubMenu__row">
            {_.map(data.children, (child, idx) => (
              <div onClick={() => goTo(child.slug)} key={child.slug + idx}>
                <p className="mobileSubSubMenu__row__paragraph">
                  {getText(child)}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default SubMenuMobile
