import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import './submenu-style.scss'
import SubMenuChildren from './components/submenu-children'

const ListSubMenu = ({
  handleSelectSubmenu,
  child,
  submenuSelected,
  titleMenuSelected,
  t,
}) => {
  const activeLink = submenuSelected.slug
    ? submenuSelected.slug === child.slug
    : submenuSelected.id === child.id
  return (
    <div
      onMouseEnter={() => handleSelectSubmenu(child)}
      className={
        activeLink
          ? 'submenu__list__button noLink hover'
          : 'submenu__list__button noLink'
      }
    >
      {/* <span>
        {titleMenuSelected === 'our-products'
          ? child.title
          : t(`menu.${titleMenuSelected}.children.${child.title}.title`, {
            country: process.env.GATSBY_COUNTRY,
          })}
      </span> */}
      <span>
        {titleMenuSelected === 'our-products'
          ? child.title
          : t(`menu.${titleMenuSelected}.children.${child.title}.title`)}
      </span>
    </div>
  )
}

const SubMenu = ({
  t,
  menuSelectedItem,
  handleMouseEnter,
  handleMouseLeave,
  menu,
}) => {
  const [menuSelected, setMenuSelected] = useState([])
  const [submenuSelected, setSubmenuSelected] = useState([])
  const [titleMenuSelected, setTitleMenuSelected] = useState(null)

  useEffect(() => {
    setTitleMenuSelected(null)
    setSubmenuSelected([])
    setMenuSelected([])
    const selected = menu[menuSelectedItem]
    const firstchild = _.get(selected, 'children') && _.get(selected, 'children[0]', null)
    setTitleMenuSelected(_.get(selected, 'title'))
    setSubmenuSelected(firstchild)
    setMenuSelected(selected)
  }, [menuSelectedItem])

  const handleSelectSubmenu = (child) => {
    setSubmenuSelected(child)
  }

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="container-fluid submenu__container"
    >
      <div className="container-xxl">
        <div className="row">
          <div className="col-3 submenu__list">
            {_.get(menuSelected, 'children')
              ? _.map(_.compact(menuSelected.children), (child, idx) => (
                <ListSubMenu
                  child={child}
                  idx={idx}
                  handleSelectSubmenu={handleSelectSubmenu}
                  submenuSelected={submenuSelected}
                  titleMenuSelected={titleMenuSelected}
                  t={t}
                />
              ))
              : null}
          </div>
          <SubMenuChildren
            titleMenuSelected={titleMenuSelected}
            submenuSelected={submenuSelected}
            menuSelectedItem={menuSelectedItem}
            t={t}
          />
        </div>
      </div>
    </div>
  )
}

export default SubMenu
