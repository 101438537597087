/* eslint-disable no-console */
import _ from 'lodash'
import enJson from './locales/en/en.json'
import phJson from './locales/ph/ph.json'
import idJson from './locales/id/id.json'

function getData(language) {
  const fallbackLng = {
    en: enJson,
    ph: phJson,
    id: idJson,
  }
  console.log('Current language:', language)
  return new Promise((resolve) => {
    fetch(
      `${process.env.GATSBY_TRANSLATIONS_URL}/locales/${language}/${language}.json`,
      {
        method: 'GET',
      },
    )
      .then((response) => response.json())
      .then((data) => {
        if (data) {          
          resolve(_.merge(fallbackLng[language], data))
        } else {
          resolve(fallbackLng[language])
        }        
      })
      .catch((error) => {
        console.error(error)
      })
  })
}

export default getData
