import _, { has } from 'lodash';
import { Helmet } from 'react-helmet';
import React, { useState, useEffect } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { withTrans } from '../../i18n/withTrans';
import Header from '../header';
import Footer from '../footer';
import Spinner from '../Spinner';
import { setCallback } from '../../i18n/config';
import MenuMobile from '../menu-mobile';
import FooterMobile from '../footer/footer-mobile';
import './layout-styles.scss';

const Layout = ({
  children, t, i18n, location,
}) => {
  const language = process.env.GATSBY_AVAILABLE_LOCALES;
  const arrayOflang = language.split(' ');
  const breakpoints = useBreakpoint();
  const [isOpenMenu] = useState(false);
  const [loading, setLoading] = useState(true);
  const socialmediafake = {
    facebook: 'https://www.facebook.com/',
    instagram: 'https://instagram.com/',
    linkedin: 'https://www.linkedin.com/',
    youtube: 'https://www.youtube.com/',
  };

  const socialMediaObject = _.reduce(
    socialmediafake,
    (result, value, key) => {
      result.push({
        name: key,
        link: value,
        size: 22,
        color: '#1C75BC',
      });

      return result;
    },
    [],
  );

  useEffect(() => {
    _.map(arrayOflang, (lng) => (_.includes(location.pathname, lng) ? i18n.changeLanguage(lng) : i18n.changeLanguage('en')));
  }, [location]);

  const allowMetaTags = [
    '/product/blue-royale/',
    '/product/select/',
    '/product/flexishield/',
    '/contact-form/',
    '/quotation/'
  ];

  const regexPattern = new RegExp(`^(${allowMetaTags.join('|')})$`);
  const isMatch = regexPattern.test(location.pathname);

  if (loading) {
    setCallback(() => setLoading(false));
    return <Spinner />;
  }

  return (
    <div className="global-wrapper layout__container">
      <Helmet>
        <meta name="referrer" content="strict-origin-when-cross-origin" />
        <body className={isOpenMenu ? 'is-open-menu' : ''} />
        <script async src="https://www.googletagmanager.com/gtm.js?id=GTM-W42GL6GP"></script>
        <script async>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '856574768311085');
            fbq('track', 'PageView');
          `}
        </script>
        <script>
        {`
          (function(w,d,s,l,i){
            w[l]=w[l]||[];
            w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
            var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),
                dl=l!='dataLayer'?'&l='+l:'';
            j.async=true;
            j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
            f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-W42GL6GP');
        `}
      </script>
        
      </Helmet>
      <Header location={location} />
      <div className="children">{children}</div>
      <MenuMobile t={t} />
      {!breakpoints.sm && (
        <Footer t={t} i18n={i18n} socialmedia={socialMediaObject} />
      )}
      {breakpoints.sm && (
        <FooterMobile t={t} i18n={i18n} socialmedia={socialMediaObject} />
      )}

      <noscript>
        <img 
          height="1"
          width="1"
          style={{display: 'none'}}
          src="https://www.facebook.com/tr?id=856574768311085&ev=PageView&noscript=1"
        />
      </noscript>

      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-W42GL6GP"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
    </div>
  );
};

export default withTrans(Layout);
